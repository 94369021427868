import React, { useState } from "react";
import InputField from "../../../../../components/Calculations/InputField";
import { useSelector } from "react-redux";
import ar from "../../../../../lang/ar/ar.json";
import en from "../../../../../lang/en/en.json";
import fr from "../../../../../lang/fr/fr.json";
// import icon from "../../../../../public/assets/icons/Feed Consumption Rate For Brown pullets Programs.webp";
import useEnterKeyPress from "../../../../../utils/EnterCalculateBtn";
import ProgramExplaination from "../../../../../shared/ProgramExplaination";

const ST_FCR = [
	0, 0.3, 0.55, 0.76, 0.97, 1.14, 1.3, 1.45, 1.57, 1.68, 1.75, 1.8, 1.86, 1.87,
	1.89, 1.9, 1.9, 1.91, 1.93, 1.93, 1.94, 1.94, 1.96, 1.98, 2.0, 2.03, 2.05,
	2.07, 2.09, 2.1, 2.12, 2.13, 2.15, 2.17, 2.19, 2.21, 2.23, 2.24, 2.26, 2.28,
	2.29, 2.31, 2.32, 2.34, 2.35, 2.36, 2.37, 2.38, 2.39, 2.4, 2.41, 2.42, 2.44,
	2.45, 2.47, 2.48, 2.49, 2.5, 2.52, 2.53, 2.54, 2.55, 2.56, 2.58, 2.59, 2.61,
	2.62, 2.64, 2.66, 2.68, 2.69, 2.71, 2.73, 2.75, 2.77, 2.79, 2.81, 2.83, 2.85,
	2.87, 2.89, 2.91, 2.93, 2.95, 2.97, 2.99, 3.02, 3.05, 3.08, 3.11, 3.14, 3.18,
	3.21, 3.25, 3.28, 3.31, 3.34, 3.38, 3.41, 3.44, 3.47, 3.5, 3.53, 3.56, 3.59,
	3.62, 3.65, 3.68, 3.71, 3.74, 3.77, 3.8, 3.83, 3.86, 3.89, 3.92, 3.94, 3.97,
	4.0, 4.03, 4.06, 4.09, 4.12, 4.15, 4.18, 4.21,
];

const FeedConvertionRatioFCRForBrownPulletsProgram = () => {
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [ageOfBirds, setAgeOfBirds] = useState("");
	const [totalFeedIntake, setTotalFeedIntake] = useState("");
	const [averageBodyWeight, setAverageBodyWeight] = useState("");
	const [result, setResult] = useState("");

	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	let newLang =
		language === "en"
			? en.services.layersManagment
			: language === "ar"
			? ar.services.layersManagment
			: fr.services.layersManagment;

	const calculateFCR = () => {
		const weeks = Math.ceil(ageOfBirds / 7);

		const normalFCR = ST_FCR[ageOfBirds];
		const res0 = totalFeedIntake / numberOfBirds;
		const userFCR = (res0 / averageBodyWeight) * 1000;

		setResult(
			`${newLang.NormalFCR} = ${normalFCR.toFixed(2)} \n\n${
				newLang.YourFCR
			} = ${userFCR.toFixed(2)}`
		);
	};

	useEnterKeyPress(calculateFCR);

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{newLang.FeedConvertionRatioFCRForBrownPulletsProgram}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.NumberOfBirds}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={lang.EnterNumberOfBirds}
					/>
					<InputField
						label={`${lang.AgeOfBirds}:`}
						value={ageOfBirds}
						onChange={(e) => setAgeOfBirds(e.target.value)}
						placeholder={lang.EnterAgeOfBirds}
					/>
					<InputField
						label={`${newLang.TotalFeedIntakeinKgfromdayonetillnow}:`}
						value={totalFeedIntake}
						onChange={(e) => setTotalFeedIntake(e.target.value)}
						placeholder={lang.EnterTotalFeedIntake}
					/>
					<InputField
						label={`${newLang.AverageWeightofbirdsingram}:`}
						value={averageBodyWeight}
						onChange={(e) => setAverageBodyWeight(e.target.value)}
						placeholder={lang.EnterAverageBodyWeight}
					/>
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={calculateFCR}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={
							"/assets/icons/Feed Consumption Rate For Brown pullets Programs.webp"
						}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
			{/* <ProgramExplaination
				programTitle={lang.feed_Analysis}
				explaination={lang.feed_Analysis}
			/> */}
		</section>
	);
};

export default FeedConvertionRatioFCRForBrownPulletsProgram;
