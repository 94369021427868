import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { drugInteractionsProgram } from "../../../../redux/services/DrugInteractionsProgram/drugInteractionsProgram";
import ProgramExplaination from "../../../../shared/ProgramExplaination";

const DrugInteractionsProgram = () => {
	const [medicine1, setMedicine1] = useState("");
	const [medicine2, setMedicine2] = useState("");
	const [result, setResult] = useState("");

	const language = useSelector((state) => state?.lang?.language);

	console.log("language 888", language);

	let langForReq = language === "ar" ? "ar" : "en";

	const dispatch = useDispatch();
	// useEffect(() => {
	// 	// Fetch data whenever either medicine1 or medicine2 changes

	// 	dispatch(drugInteractionsProgram({ medicine1: 7, medicine2: 2 }));
	// }, [dispatch]); // Dependencies: when medicine1 or medicine2 changes

	return (
		<section
			className='container pt-150'
			// dir={language === "ar" ? "rtl" : "ltr"}
		>
			<div>
				<label htmlFor='medicine1'>Medicine 1:</label>
				<input
					id='medicine1'
					type='text'
					value={medicine1}
					onChange={(e) => setMedicine1(e.target.value)}
				/>

				<label htmlFor='medicine2'>Medicine 2:</label>
				<input
					id='medicine2'
					type='text'
					value={medicine2}
					onChange={(e) => setMedicine2(e.target.value)}
				/>
				<button
					onClick={() =>
						dispatch(
							drugInteractionsProgram({
								medicine1: medicine1,
								medicine2: medicine2,
								lang: langForReq,
							})
						)
					}
				>
					Calculate
				</button>
				<div id='medicine_result'>{result}</div>
				{/* <ProgramExplaination
					programTitle={lang.Water_Consumption_Rate}
					explaination={lang.Water_Consumption_Rate_explaination}
				/> */}
			</div>
		</section>
	);
};

export default DrugInteractionsProgram;
