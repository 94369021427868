import React, { useState } from "react";
import InputField from "../../../../../components/Calculations/InputField";
import { useSelector } from "react-redux";
import ar from "../../../../../lang/ar/ar.json";
import en from "../../../../../lang/en/en.json";
import fr from "../../../../../lang/fr/fr.json";
// import icon from "../../../../../public/assets/icons/Water Consumption Rate Program.webp"; // Example icon
import useEnterKeyPress from "../../../../../utils/EnterCalculateBtn";
import ProgramExplaination from "../../../../../shared/ProgramExplaination";

const waterConsumptionArray = [
	109, 109, 110, 110, 110, 110, 110, 120, 120, 120, 120, 120, 120, 120, 125,
	125, 125, 125, 125, 125, 125, 125, 127, 127, 127, 127, 127, 127, 127, 130,
	130, 130, 130, 130, 130, 130, 130, 135, 135, 135, 135, 135, 135, 135, 140,
	140, 140, 140, 140, 140, 140, 140, 145, 145, 145, 145, 145, 145, 145, 145,
	145, 145, 145, 145, 145, 145, 150, 150, 150, 150, 150, 150, 150, 150, 150,
	150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150,
	150, 150, 150, 150, 150, 150, 150, 160, 160, 160, 160, 160, 160, 160, 160,
	160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160,
	160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160,
	160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160,
	160, 160, 160, 160, 160, 160, 160, 165, 165, 165, 165, 165, 165, 165, 165,
	165, 165, 165, 165, 165, 165, 165, 165, 165, 165, 165, 165, 165, 165, 165,
	165, 165, 165, 165, 165, 165, 165, 165, 165, 165, 165, 165, 165, 165, 165,
	165, 165, 165, 165, 165, 165, 165, 165, 165, 165, 165, 170, 170, 170, 170,
	170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170,
	170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170,
	170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170,
	170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170,
	170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170,
	170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170, 170,
	170, 170, 170, 170, 165, 165, 165, 165, 165, 165, 165, 165, 165, 165, 165,
	165, 165, 165, 165, 165, 165, 165, 165, 165, 165, 165, 165, 165, 165, 165,
	165, 165, 165, 165, 165, 165, 165, 165, 165, 165, 165, 165, 165, 165, 165,
	165, 165, 165, 165, 165, 165, 165, 165, 160, 160, 160, 160, 160, 160, 160,
	160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160,
	160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160,
	160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 160, 158, 158, 158,
	158, 158, 158, 158, 158, 158, 158, 158, 158, 158, 158, 158, 158, 158, 158,
	158, 158, 158, 158, 158, 158, 158, 158, 158, 158, 158, 158, 158, 158, 158,
	158, 158, 158, 158, 158, 158, 158, 158, 158, 158, 158, 158, 158, 158, 158,
	158, 158, 158, 158, 158, 158, 158, 158, 155, 155, 155, 155, 155, 155, 155,
	155, 155, 155, 155, 155, 155, 155, 155, 155, 155, 155, 155, 155, 155, 155,
	155, 155, 155, 155, 155, 155, 155, 155, 155, 155, 155, 155, 155, 155, 155,
	155, 155, 155, 155, 155, 155, 155, 155, 155, 155, 155, 155, 155, 155, 155,
	155, 155, 155, 155, 155, 155, 155, 155, 155, 155, 155, 155, 155, 155, 155,
	155, 155, 155, 155, 155, 155, 155, 155, 155, 155, 155, 155, 155, 155,
];

const WaterConsumptionRateForWhiteLayingHensProgram = () => {
	const [ageOfBirds, setAgeOfBirds] = useState("");
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [result, setResult] = useState("");
	const [weeks, setWeeks] = useState("");
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	let newLang =
		language === "en"
			? en.services.layersManagment
			: language === "ar"
			? ar.services.layersManagment
			: fr.services.layersManagment;

	const handleCalculation = () => {
		const age = parseInt(ageOfBirds, 10);
		const number = parseInt(numberOfBirds, 10);

		if (age < 120 || age > 658) {
			alert(
				lang.MinimumAgeMessage ||
					"Minimum Age 120 days (18 weeks) and Maximum age 658 days (94 weeks)"
			);
			return;
		}

		const calculatedWeeks = Math.ceil(age / 7);
		setWeeks(calculatedWeeks);

		const index = age - 120;
		const waterRate = (waterConsumptionArray[index] * number) / 1000;

		setResult(
			`${newLang.WaterConsumptionRate} = ${waterRate.toFixed(0)} ${lang.Liter}`
		);
	};

	useEnterKeyPress(handleCalculation);

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{
					newLang.whiteLayingHensManagmentPrograms
						.WaterConsumptionRateForWhiteLayingHensProgram
				}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.NumberOfBirds}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={lang.EnterNumberOfBirds}
					/>
					<InputField
						label={`${lang.AgeOfBirds}:`}
						value={ageOfBirds}
						onChange={(e) => setAgeOfBirds(e.target.value)}
						placeholder={lang.EnterAgeOfBirds}
					/>
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={handleCalculation}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={"/assets/icons/Water Consumption Rate Program.webp"}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
			{/* <ProgramExplaination
				programTitle={lang.feed_Analysis}
				explaination={lang.feed_Analysis}
			/> */}
		</section>
	);
};

export default WaterConsumptionRateForWhiteLayingHensProgram;
