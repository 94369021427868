import React, { useState } from "react";
import InputField from "../../../../../components/Calculations/InputField";
import { useSelector } from "react-redux";
import ar from "../../../../../lang/ar/ar.json";
import en from "../../../../../lang/en/en.json";
import fr from "../../../../../lang/fr/fr.json";
// import icon from "../../../../../public/assets/icons/Feed Consumption Rate Program.webp"; // may not
import useEnterKeyPress from "../../../../../utils/EnterCalculateBtn";
import ProgramExplaination from "../../../../../shared/ProgramExplaination";

function FeedConsumptionRateForBrownLayingHensProgram() {
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [ageOfBirds, setAgeOfBirds] = useState("");
	const [week, setWeek] = useState("");
	const [result, setResult] = useState("");

	// Use an empty array for FEDD_STD_ARRAY initially
	const FEDD_STD_ARRAY = [
		83, 84, 85, 86, 87, 88, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
		100, 100, 101, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112,
		113, 113, 114, 114, 115, 115, 116, 116, 116, 116, 117, 117, 117, 118, 118,
		118, 119, 119, 119, 119, 120, 120, 120, 120, 120, 120, 120, 120, 120, 121,
		121, 121, 121, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122,
		122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122,
		122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122,
		122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122,
		122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122,
		122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122,
		122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122,
		122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122,
		122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122,
		122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122,
		122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122,
		122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122,
		122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122, 122,
		122, 122, 122, 122, 122, 122, 122, 122, 123, 123, 123, 123, 123, 123, 123,
		123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123,
		123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123,
		123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123,
		123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123,
		123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123,
		123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123,
		123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123,
		123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123,
		123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123,
		123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123,
		123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123,
		123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123, 123,
		123, 123, 123, 123, 123, 123, 124, 124, 124, 124, 124, 124, 124, 124, 124,
		124, 124, 124, 124, 124, 124, 124, 124, 124, 124, 124, 124, 124, 124, 124,
		124, 124, 124, 124, 124, 124, 124, 124, 124, 124, 124, 124, 124, 124, 124,
		124, 124, 124, 124, 124, 124, 124, 124, 124, 124, 124, 124, 124, 124, 124,
		124, 124, 124, 124, 124, 124, 124, 124, 124, 124, 124, 124, 124, 124, 124,
		124, 124, 124, 124, 124, 124, 124, 124, 124, 124, 124,
	]; // Add your array here

	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	let newLang =
		language === "en"
			? en.services.layersManagment
			: language === "ar"
			? ar.services.layersManagment
			: fr.services.layersManagment;

	const calculateFeedConsumption = () => {
		const birds = parseFloat(numberOfBirds);
		const age = parseFloat(ageOfBirds);

		if (isNaN(birds) || isNaN(age) || birds <= 0 || age <= 0) {
			setResult(lang.InvalidInputMessage);
			return;
		}

		if (age < 120 || age > 658) {
			alert(newLang.BirdAgeExceedsLimit); // Handle localized message
			return;
		}

		const calculatedWeek = Math.ceil(age / 7);
		setWeek(calculatedWeek);

		const index = age - 120;
		if (
			FEDD_STD_ARRAY.length > 0 &&
			index >= 0 &&
			index < FEDD_STD_ARRAY.length
		) {
			const DFI = FEDD_STD_ARRAY[index];
			const feedIntake = Math.round((birds * DFI) / 1000);
			setResult(
				`${newLang.dailyFeedIntake} = ${feedIntake} Kg \n${calculatedWeek} ${newLang.weeks}`
			);
		} else {
			setResult(newLang.FeedDataNotAvailable);
		}
	};

	useEnterKeyPress(calculateFeedConsumption); // Custom hook to calculate on Enter

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{
					newLang.brownLayingHensManagmentPrograms
						.FeedConsumptionRateForBrownLayingHensProgram
				}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.NumberOfBirds}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={lang.EnterNumberOfBirds}
					/>
					<InputField
						label={`${lang.AgeOfBirds}:`}
						value={ageOfBirds}
						onChange={(e) => setAgeOfBirds(e.target.value)}
						placeholder={lang.EnterAgeInDays}
					/>
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={calculateFeedConsumption}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={"/assets/icons/Feed Consumption Rate Program.webp"}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
			{/* <ProgramExplaination
				programTitle={lang.feed_Analysis}
				explaination={lang.feed_Analysis}
			/> */}
		</section>
	);
}

export default FeedConsumptionRateForBrownLayingHensProgram;
