import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";
import ar from "../lang/ar/ar.json";
import en from "../lang/en/en.json";
import fr from "../lang/fr/fr.json";

const AlertModal = ({ variable, isOpen, onClose }) => {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en" ? en.global : language === "ar" ? ar.global : fr.global;
	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			aria-labelledby='alert-modal-title'
			aria-describedby='alert-modal-description'
		>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 400,
					bgcolor: "background.paper",
					borderRadius: 2,
					boxShadow: 24,
					p: 4,
					textAlign: "center",
				}}
			>
				<Typography
					id='alert-modal-title'
					variant='h6'
					component='h2'
				>
					{variable} {lang.is_under_construction}
				</Typography>
				<Button
					variant='contained'
					color='primary'
					sx={{ mt: 2 }}
					onClick={onClose}
				>
					{lang.close}
				</Button>
			</Box>
		</Modal>
	);
};

export default AlertModal;
