import React, { useState } from "react";
import { useSelector } from "react-redux";
import ar from "../../../../lang/ar/ar.json";
import en from "../../../../lang/en/en.json";
import fr from "../../../../lang/fr/fr.json";
import useEnterKeyPress from "../../../../utils/EnterCalculateBtn";
// import icon from "../../../../public/assets/icons/Total Feed Consumption For White Laying Hens Program.webp"; // Optional: Add an icon if required
import InputField from "../../../../components/Calculations/InputField"; // Reused InputField component
import ProgramExplaination from "../../../../shared/ProgramExplaination";

const CFI = [
	0, 15, 32, 52, 75, 101, 130, 162, 197, 235, 278, 325, 378, 435, 498, 565, 638,
	715, 797, 883, 974, 1069, 1170, 1277, 1390, 1509, 1634, 1765, 1902, 2045,
	2194, 2345, 2503, 2669, 2843, 3025, 3210, 3401, 3597, 3798, 4002, 4207, 4412,
	4618, 4825, 5033, 5243, 5455, 5668, 5882, 6096, 6310, 6526, 6743, 6961, 7180,
	7400, 7622, 7846, 8071, 8297,
];

const TotalFeedConsumptionRateProgram = () => {
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [ageOfBirds, setAgeOfBirds] = useState("");
	const [result, setResult] = useState("");

	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	const calculateResults = () => {
		const numBirds = parseFloat(numberOfBirds);
		const age = parseInt(ageOfBirds, 10);

		if (!isNaN(numBirds) && !isNaN(age) && age >= 0 && age < CFI.length) {
			const cfiValue = CFI[age];
			const res1 = (cfiValue * numBirds) / 1000;
			const res2 = res1 / 1000;

			setResult(
				`${lang.NormalCumulativeFeedIntake} = ${res1.toFixed(
					2
				)} Kg = ${res2.toFixed(2)} ton.`
			);
		} else {
			setResult(lang.InvalidInputMessage);
		}
	};

	useEnterKeyPress(calculateResults);

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{lang.TotalFeedConsumptionRateProgram}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.NumberOfBirds}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={lang.EnterNumberOfBirds}
					/>
					<InputField
						label={`${lang.AgeOfBirds}:`}
						value={ageOfBirds}
						onChange={(e) => setAgeOfBirds(e.target.value)}
						placeholder={lang.EnterAgeOfBirds}
					/>
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={calculateResults}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={
							"/assets/icons/Total Feed Consumption For White Laying Hens Program.webp"
						}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
			<ProgramExplaination
				programTitle={lang.Total_Feed_Consumption_Rate}
				explaination={lang.Total_Feed_Consumption_Rate_explaination}
			/>
		</section>
	);
};

export default TotalFeedConsumptionRateProgram;
