import React, { useState } from "react";
import InputField from "../../../../../components/Calculations/InputField";
import { useSelector } from "react-redux";
import ar from "../../../../../lang/ar/ar.json";
import en from "../../../../../lang/en/en.json";
import fr from "../../../../../lang/fr/fr.json";
// import icon from "../../../../../public/assets/icons/Normal Body Weight Program.webp"; // Updated icon
import useEnterKeyPress from "../../../../../utils/EnterCalculateBtn";
import ProgramExplaination from "../../../../../shared/ProgramExplaination";

// Define the ST_B_W array in JavaScript
const ST_B_W = [
	1241, 1245, 1249, 1253, 1257, 1261, 1265, 1270, 1275, 1280, 1285, 1290, 1295,
	1300, 1306, 1312, 1318, 1324, 1330, 1336, 1342, 1348, 1354, 1360, 1366, 1372,
	1378, 1384, 1390, 1396, 1402, 1408, 1414, 1420, 1426, 1432, 1438, 1443, 1448,
	1452, 1455, 1458, 1461, 1464, 1467, 1470, 1473, 1475, 1477, 1479, 1481, 1483,
	1484, 1485, 1486, 1487, 1488, 1489, 1490, 1491, 1492, 1493, 1494, 1495, 1496,
	1497, 1498, 1499, 1500, 1500, 1500, 1500, 1500, 1500, 1501, 1502, 1503, 1504,
	1505, 1506, 1507, 1508, 1509, 1510, 1511, 1512, 1513, 1514, 1515, 1516, 1517,
	1518, 1519, 1520, 1521, 1522, 1523, 1524, 1525, 1526, 1527, 1528, 1529, 1530,
	1531, 1532, 1533, 1534, 1535, 1536, 1537, 1538, 1539, 1540, 1541, 1542, 1543,
	1544, 1545, 1546, 1547, 1548, 1549, 1550, 1550, 1550, 1550, 1550, 1550, 1550,
	1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550,
	1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550,
	1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550,
	1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550,
	1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550,
	1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550,
	1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550,
	1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550,
	1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550,
	1550, 1550, 1550, 1550, 1550, 1550, 1550, 1550, 1555, 1555, 1555, 1555, 1555,
	1555, 1555, 1555, 1555, 1555, 1555, 1555, 1555, 1555, 1555, 1555, 1555, 1555,
	1555, 1555, 1555, 1555, 1555, 1555, 1555, 1555, 1555, 1555, 1560, 1560, 1560,
	1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560,
	1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560,
	1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560,
	1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560,
	1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560,
	1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560, 1560,
	1560, 1560, 1560, 1560, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570,
	1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570,
	1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570,
	1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570,
	1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570,
	1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570,
	1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570,
	1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570,
	1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570,
	1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570,
	1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570,
	1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570,
	1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570, 1570,
	1570, 1570, 1570, 1570, 1570, 1570,
];

const NormalBodyWeightForWhiteLayingHensProgram = () => {
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [ageOfBirds, setAgeOfBirds] = useState("");
	const [weeks, setWeeks] = useState("");
	const [result, setResult] = useState("");

	const language = useSelector((state) => state?.lang?.language);
	const lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	const newLang =
		language === "en"
			? en.services.layersManagment
			: language === "ar"
			? ar.services.layersManagment
			: fr.services.layersManagment;

	const calculateResults = () => {
		const numBirds = parseFloat(numberOfBirds);
		const age = parseFloat(ageOfBirds);

		if (age < 120) {
			alert(lang.AgeTooLowMessage); // Add this line to show an alert when age is too low
			setResult("");
			return;
		}

		if (!isNaN(numBirds) && !isNaN(age) && numBirds >= 0 && age >= 120) {
			// Calculate the week based on the bird's age
			const weeksCalculated = Math.ceil(age / 7);
			setWeeks(weeksCalculated);

			// Get the standard body weight value for the given age
			const index = age - 120;
			const normalBodyWeight = ST_B_W[index] || "N/A"; // Handle case if age is out of range

			// Update the result
			const res1 = normalBodyWeight / 1000; // Convert to Kg
			setResult(
				`${newLang.Normalbodyweight} = ${res1.toFixed(
					2
				)} Kg.\n\n${weeksCalculated} ${newLang.weeks}`
			);
		} else {
			setResult(lang.InvalidInputMessage);
		}
	};

	useEnterKeyPress(calculateResults);

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{
					newLang.whiteLayingHensManagmentPrograms
						.NormalBodyWeightForWhiteLayingHensProgram
				}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.NumberOfBirds}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={lang.EnterNumberOfBirds}
					/>
					<InputField
						label={`${lang.AgeOfBirds}:`}
						value={ageOfBirds}
						onChange={(e) => setAgeOfBirds(e.target.value)}
						placeholder={lang.EnterAgeOfBirds}
					/>
					{/* Uncomment if needed */}
					{/* <InputField
            label={`${lang.Weeks}:`}
            value={weeks}
            placeholder={lang.WeeksCalculatedPlaceholder}
            readOnly
          /> */}
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={calculateResults}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={"/assets/icons/Normal Body Weight Program.webp"}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
			{/* <ProgramExplaination
				programTitle={lang.feed_Analysis}
				explaination={lang.feed_Analysis}
			/> */}
		</section>
	);
};

export default NormalBodyWeightForWhiteLayingHensProgram;
