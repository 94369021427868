import React from "react";

const ProgramExplaination = ({ programTitle, explaination }) => {
	return (
		<div className='feed-consumption-description'>
			<h2 className='title'>{programTitle}</h2>
			<p className='description'>{explaination}</p>
		</div>
	);
};

export default ProgramExplaination;
