import React, { useState } from "react";
import InputField from "../../../../../components/Calculations/InputField"; // Update the path as needed
import { useSelector } from "react-redux";
import ar from "../../../../../lang/ar/ar.json";
import en from "../../../../../lang/en/en.json";
import fr from "../../../../../lang/fr/fr.json";
import useEnterKeyPress from "../../../../../utils/EnterCalculateBtn"; // Custom hook for Enter key
import ProgramExplaination from "../../../../../shared/ProgramExplaination";
// import icon from "../../../../../public/assets/icons/Normal Body Weight For Brown Laying Hens Program.webp";

const ST_B_W = [
	1470, 1480, 1490, 1500, 1510, 1520, 1530, 1535, 1540, 1545, 1550, 1555, 1560,
	1565, 1575, 1585, 1595, 1605, 1615, 1625, 1635, 1640, 1645, 1650, 1655, 1670,
	1675, 1680, 1685, 1690, 1695, 1700, 1705, 1710, 1715, 1720, 1725, 1730, 1735,
	1740, 1745, 1750, 1751, 1752, 1753, 1754, 1755, 1756, 1757, 1758, 1760, 1762,
	1764, 1766, 1768, 1770, 1772, 1774, 1776, 1778, 1780, 1782, 1784, 1786, 1788,
	1790, 1792, 1794, 1796, 1798, 1800, 1801, 1802, 1803, 1804, 1805, 1806, 1807,
	1808, 1810, 1812, 1814, 1816, 1818, 1819, 1820, 1821, 1822, 1823, 1824, 1825,
	1826, 1827, 1828, 1830, 1832, 1834, 1836, 1837, 1838, 1839, 1840, 1841, 1842,
	1843, 1844, 1845, 1846, 1847, 1848, 1849, 1850, 1851, 1852, 1853, 1854, 1855,
	1856, 1857, 1858, 1859, 1860, 1861, 1862, 1863, 1864, 1865, 1866, 1867, 1868,
	1869, 1870, 1870, 1871, 1872, 1873, 1874, 1875, 1876, 1877, 1878, 1879, 1880,
	1881, 1882, 1882, 1883, 1884, 1884, 1885, 1886, 1887, 1888, 1888, 1889, 1889,
	1890, 1890, 1891, 1892, 1893, 1893, 1893, 1894, 1894, 1894, 1895, 1895, 1896,
	1897, 1898, 1899, 1900, 1900, 1900, 1901, 1901, 1902, 1903, 1904, 1905, 1905,
	1905, 1906, 1906, 1906, 1907, 1907, 1908, 1908, 1908, 1909, 1909, 1910, 1910,
	1910, 1911, 1911, 1912, 1912, 1913, 1913, 1914, 1914, 1914, 1915, 1915, 1915,
	1916, 1916, 1916, 1917, 1918, 1918, 1919, 1919, 1920, 1920, 1920, 1921, 1921,
	1922, 1922, 1922, 1923, 1923, 1923, 1924, 1924, 1925, 1925, 1925, 1926, 1926,
	1927, 1927, 1928, 1928, 1928, 1929, 1929, 1930, 1930, 1931, 1931, 1932, 1932,
	1932, 1933, 1933, 1934, 1934, 1935, 1935, 1935, 1936, 1936, 1937, 1937, 1937,
	1938, 1939, 1940, 1941, 1942, 1943, 1943, 1943, 1943, 1944, 1944, 1944, 1944,
	1945, 1945, 1945, 1946, 1946, 1946, 1947, 1947, 1947, 1948, 1948, 1948, 1949,
	1949, 1949, 1950, 1950, 1950, 1951, 1951, 1951, 1951, 1952, 1952, 1953, 1953,
	1953, 1953, 1953, 1954, 1954, 1954, 1954, 1954, 1954, 1955, 1955, 1955, 1955,
	1955, 1955, 1955, 1955, 1955, 1956, 1956, 1956, 1956, 1956, 1957, 1957, 1957,
	1957, 1957, 1957, 1958, 1958, 1958, 1958, 1958, 1959, 1959, 1959, 1959, 1959,
	1960, 1960, 1960, 1960, 1960, 1960, 1960, 1960, 1961, 1961, 1961, 1961, 1961,
	1961, 1961, 1961, 1962, 1962, 1962, 1962, 1962, 1962, 1962, 1962, 1963, 1963,
	1963, 1963, 1963, 1963, 1963, 1964, 1964, 1964, 1964, 1964, 1964, 1964, 1964,
	1964, 1965, 1965, 1965, 1965, 1965, 1965, 1965, 1966, 1966, 1966, 1966, 1966,
	1966, 1966, 1967, 1967, 1967, 1967, 1967, 1967, 1967, 1968, 1968, 1968, 1968,
	1968, 1968, 1968, 1969, 1969, 1969, 1969, 1969, 1969, 1969, 1969, 1969, 1969,
	1969, 1969, 1969, 1969, 1969, 1969, 1969, 1969, 1969, 1969, 1969, 1970, 1970,
	1970, 1970, 1970, 1970, 1970, 1970, 1970, 1970, 1970, 1970, 1970, 1970, 1971,
	1971, 1971, 1971, 1971, 1971, 1971, 1971, 1971, 1971, 1971, 1971, 1971, 1971,
	1972, 1972, 1972, 1972, 1972, 1972, 1972, 1972, 1972, 1972, 1972, 1972, 1972,
	1972, 1973, 1973, 1973, 1973, 1973, 1973, 1973, 1973, 1973, 1973, 1973, 1973,
	1973, 1973, 1974, 1974, 1974, 1974, 1974, 1974, 1974, 1974, 1974, 1974, 1974,
	1974, 1974, 1974, 1975, 1975, 1975, 1975, 1975, 1975, 1975, 1975, 1975, 1975,
	1975, 1975, 1975, 1975, 1976, 1976, 1976, 1976, 1976, 1976, 1976, 1976, 1976,
	1976, 1976, 1976, 1976, 1976, 1977, 1977, 1977, 1977, 1977, 1977, 1977, 1977,
	1977, 1977, 1977, 1977, 1977, 1977,
];

const WeightCalculator = () => {
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [ageOfBirds, setAgeOfBirds] = useState("");
	const [result, setResult] = useState("");
	const [error, setError] = useState("");

	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	let newLang =
		language === "en"
			? en.services.layersManagment
			: language === "ar"
			? ar.services.layersManagment
			: fr.services.layersManagment;

	const handleCalculate = () => {
		const age = parseFloat(ageOfBirds);

		if (age < 120 || age > 658) {
			setError(
				lang.InvalidAgeMessage ||
					"Minimum Age 120 days (18 weeks) and Maximum age 658 days (94 weeks)"
			);
			setResult("");
		} else {
			const index = age - 120;

			if (index >= 0 && index < ST_B_W.length) {
				const weight = ST_B_W[index] / 1000;
				setResult(`${newLang.Normalbodyweight} = ${weight} Kg.`);
				setError("");
			} else {
				setResult("");
				setError(
					lang.AgeOutOfRangeMessage || "Age out of range in the data array."
				);
			}
		}
	};

	useEnterKeyPress(handleCalculate); // Apply Enter key press functionality

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{
					newLang.brownLayingHensManagmentPrograms
						.NormalBodyWeightForBrownLayingHensProgram
				}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.NumberOfBirds}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={lang.EnterNumberOfBirds}
					/>
					<InputField
						label={`${lang.AgeOfBirds}:`}
						value={ageOfBirds}
						onChange={(e) => setAgeOfBirds(e.target.value)}
						placeholder={lang.EnterAgeOfBirds}
					/>

					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={handleCalculate}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={
							"/assets/icons/Normal Body Weight For Brown Laying Hens Program.webp"
						}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
			{error && <p style={{ color: "red" }}>{error}</p>}
			{result && <p>{result}</p>}
			{/* <ProgramExplaination
				programTitle={lang.feed_Analysis}
				explaination={lang.feed_Analysis}
			/> */}
		</section>
	);
};

export default WeightCalculator;
