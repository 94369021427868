import React from "react";
import "./Banner.scss";
import { Link } from "react-router-dom";

import en from "../../lang/en/en.json";
import ar from "../../lang/ar/ar.json";
import fr from "../../lang/fr/fr.json";
import { useSelector } from "react-redux";

const Banner = () => {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en" ? en.hero : language === "ar" ? ar.hero : fr.hero;

	console.log("language banner", lang);

	return (
		<section
			className='section-bg section-common banner-section'
			style={{ marginTop: "120px" }}
			dir={language === "ar" && "rtl"}
		>
			<div className='d-table'>
				<div className='d-table-cell'>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-6'>
								<div
									className='banner-text'
									data-aos='fade-up'
									data-aos-duration='2000'
								>
									<h1>{lang.title}</h1>
									<p>{lang.main_desc}</p>

									<div className='banner-bottom'>
										<div className='theme-btn'>
											<Link to='/contact'>{lang.book_now}</Link>
										</div>

										<div className='banner-call'>
											<div className='icon'>
												<img
													src='/assets/banner/icons/Calling.png'
													alt='icon'
												/>
											</div>
											<div className='call-text'>
												<p>{lang.contact_from}</p>
												<h6>
													<a
														href='https://wa.me/201000135613'
														target='_blank'
														rel='noopener noreferrer'
														style={{
															color: "#181945",
															textDecorationLine: "none",
															textWrap: "nowrap",
														}}
													>
														002 01000135613
													</a>
												</h6>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='col-lg-6'>
								<div
									className='banner-img-area'
									data-aos='fade-up'
									data-aos-duration='2000'
									data-aos-delay='500'
								>
									<div className='banner-img'>
										<img
											src='/assets/SVG/dr.pic.webp'
											alt='banner model'
										/>
									</div>
									<div className='info-box'>
										<div className='info-img'>
											<img
												src='/assets/banner/dr.png'
												alt='doctor'
												width={60}
												height={60}
											/>
										</div>
										<div className='info-text'>
											<p>{lang.dr_name}</p>
											<p>
												<small>{lang.Consultant}</small>
											</p>
										</div>
									</div>

									{/* Vector Shapes */}
									<div className='shapes'>
										{/* Uncomment these lines if needed */}
										{/* <img src='/assets/banner/vector_01.png' alt='shape' /> */}
										{/* <img src='/assets/banner/vector_02.png' alt='shape' /> */}
										<img
											src='/assets/banner/vector_03.png'
											alt='shape'
										/>
										<img
											src='/assets/banner/pattern.png'
											alt='shape'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Banner Pattern Vector */}
			<img
				className='banner-pattern'
				src='/assets/banner/pattern_02.png'
				alt='banner pattern'
			/>
		</section>
	);
};

export default Banner;
