import React from "react";
import "./Register.scss";
// import icon from "../../public/assets/banner/icons/Calling.png";
import { Link } from "react-router-dom";

const RegisterForm = ({ lang }) => {
	return (
		<form>
			<div className='row'>
				<div className='col-lg-6'>
					<div class='form-group'>
						<label>{lang.name}</label>
						<input
							type='text'
							class='form-control'
							placeholder={lang.enter_your_name + "..."}
						/>
					</div>
				</div>
				<div className='col-lg-6'>
					<div class='form-group'>
						<label>{lang.email}</label>
						<input
							type='email'
							class='form-control'
							placeholder={lang.enter_your_email + "..."}
						/>
					</div>
				</div>
				<div className='col-lg-6'>
					<div class='form-group'>
						<label>{lang.phone}</label>
						<input
							type='text'
							class='form-control'
							placeholder={lang.enter_your_phone + "..."}
						/>
					</div>
				</div>
				<div className='col-lg-6'>
					<div class='form-group'>
						<label>{lang.password}</label>
						<input
							type='password'
							class='form-control'
							placeholder={lang.enter_your_password + "..."}
						/>
					</div>
				</div>

				<div
					className='col-lg-6'
					style={{
						display: "flex",
						alignItems: "baseline",
						gap: "10px",
					}}
				>
					<button
						type='submit'
						class='btn appointment-btn'
					>
						{lang.register}
					</button>
					<Link to={"/login"}>{lang.sing_in}</Link>
				</div>
				<div className='col-lg-6'>
					<div className='appointment-call'>
						<div className='icon'>
							<img
								src={"/assets/banner/icons/Calling.png"}
								alt='icon'
							/>
						</div>
						<div className='call-text'>
							<p>{lang.dr_name}</p>
							<h6>
								<a
									href='https://wa.me/201000135613'
									target='_blank'
									rel='noopener noreferrer'
									style={{
										color: "#181945",
										textDecorationLine: "none",
										textWrap: "nowrap",
									}}
								>
									002 01000135613
								</a>
							</h6>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

export default RegisterForm;
