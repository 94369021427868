import React, { useState } from "react";
import { useSelector } from "react-redux";
import ar from "../../../../lang/ar/ar.json";
import en from "../../../../lang/en/en.json";
import fr from "../../../../lang/fr/fr.json";
import InputField from "../../../../components/Calculations/InputField";
// import icon from "../../../../public/assets/icons/Normal Body Weight Program.webp"; // Update the path if needed
import useEnterKeyPress from "../../../../utils/EnterCalculateBtn";
import ProgramExplaination from "../../../../shared/ProgramExplaination";

const NormalBodyWeightProgram = () => {
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [ageOfBirds, setAgeOfBirds] = useState("");
	const [result, setResult] = useState("");

	const ST_B_W = [
		40, 55, 72, 90, 109, 129, 151, 175, 201, 230, 262, 298, 338, 382, 430, 481,
		535, 592, 652, 715, 781, 850, 921, 993, 1066, 1141, 1218, 1297, 1377, 1458,
		1540, 1622, 1705, 1790, 1875, 1961, 2053, 2143, 2233, 2323, 2413, 2503,
		2593, 2682, 2772, 2862, 2951, 3039, 3128, 3216, 3303, 3389, 3475, 3560,
		3645, 3729, 3812, 3892, 3971, 4049, 4126,
	];

	const handleCalculate = () => {
		const age = parseInt(ageOfBirds, 10);

		if (isNaN(age) || age < 0 || age >= ST_B_W.length) {
			setResult("Please enter a valid age.");
			return;
		}

		const bodyWeightKg = ST_B_W[age] / 1000;
		setResult(`Normal body weight = ${bodyWeightKg} Kg`);
	};

	useEnterKeyPress(handleCalculate);

	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>{lang.Normal_Body_Weight}</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.numberOfBirds}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder='Enter number of birds'
					/>
					<InputField
						label={`${lang.ageOfBirds} (${lang.days}):`}
						value={ageOfBirds}
						onChange={(e) => setAgeOfBirds(e.target.value)}
						placeholder='Enter age of birds'
					/>
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={handleCalculate}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={"/assets/icons/Normal Body Weight Program.webp"}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
			<ProgramExplaination
				programTitle={lang.Normal_Body_Weight}
				explaination={lang.Normal_Body_Weight_explaination}
			/>
		</section>
	);
};

export default NormalBodyWeightProgram;
