import React, { useState } from "react";
import InputField from "../../../../../components/Calculations/InputField";
import { useSelector } from "react-redux";
import ar from "../../../../../lang/ar/ar.json";
import en from "../../../../../lang/en/en.json";
import fr from "../../../../../lang/fr/fr.json";
// import icon from "../../../../../public/assets/icons/Feed Consumption Rate Program.webp"; // Updated icon
import useEnterKeyPress from "../../../../../utils/EnterCalculateBtn";
import ProgramExplaination from "../../../../../shared/ProgramExplaination";

function FeedConsumptionRateForWhitepulletsPrograms() {
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [ageOfBirds, setAgeOfBirds] = useState("");
	const [result, setResult] = useState("");
	const [weeks, setWeeks] = useState(""); // Added state for weeks

	// Feed standard array (equivalent to PHP $FEDD_STD_ARRAY)
	const DFI = [
		0, 14, 14, 14, 15, 15, 15, 15, 15, 16, 16, 17, 17, 18, 18, 18, 18, 19, 19,
		20, 20, 20, 21, 22, 24, 26, 28, 30, 32, 34, 35, 37, 38, 39, 40, 41, 41, 42,
		42, 43, 43, 43, 43, 43, 43, 43, 43, 44, 44, 44, 45, 45, 45, 46, 46, 47, 47,
		47, 48, 48, 49, 49, 50, 50, 50, 50, 51, 51, 52, 53, 53, 53, 53, 53, 54, 54,
		55, 55, 55, 55, 55, 56, 56, 56, 56, 56, 56, 56, 57, 57, 58, 58, 58, 58, 58,
		58, 59, 59, 59, 59, 59, 59, 59, 60, 60, 60, 60, 60, 60, 61, 61, 61, 62, 62,
		62, 63, 63, 63, 64, 65, 67, 68, 69, 70, 71, 72, 73,
	];

	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	let newLang =
		language === "en"
			? en.services.layersManagment
			: language === "ar"
			? ar.services.layersManagment
			: fr.services.layersManagment;

	const calculateResults = () => {
		const numBirds = parseFloat(numberOfBirds);
		const age = parseFloat(ageOfBirds);

		if (!isNaN(numBirds) && !isNaN(age) && numBirds >= 0 && age >= 0) {
			// Calculate the week based on the bird's age
			const weeksCalculated = Math.ceil(age / 7);
			setWeeks(weeksCalculated); // Still setting weeks in the state

			// Daily feed intake logic based on age and number of birds
			const feedConsumption = (DFI[age] * numBirds) / 1000;
			const roundedFeedConsumption = Math.round(feedConsumption);

			// Update result with feed consumption and weeksCalculated directly
			setResult(
				`${newLang.NormalFeedIntake} = ${roundedFeedConsumption} ${lang.Kg} \n${weeksCalculated} ${newLang.weeks}`
			);
		} else {
			setResult(lang.InvalidInputMessage);
		}
	};

	useEnterKeyPress(calculateResults);

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{
					newLang.whitePulletsManagmentPrograms
						.FeedConsumptionRateForWhitepulletsPrograms
				}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.NumberOfBirds}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={lang.EnterNumberOfBirds}
					/>
					<InputField
						label={`${lang.AgeOfBirds}:`}
						value={ageOfBirds}
						onChange={(e) => setAgeOfBirds(e.target.value)}
						placeholder={lang.EnterAgeOfBirds}
					/>
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={calculateResults}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={"/assets/icons/Feed Consumption Rate Program.webp"}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
			{/* <ProgramExplaination
				programTitle={lang.feed_Analysis}
				explaination={lang.feed_Analysis}
			/> */}
		</section>
	);
}

export default FeedConsumptionRateForWhitepulletsPrograms;
