import React, { useState } from "react";
import InputField from "../../../../../components/Calculations/InputField";
import { useSelector } from "react-redux";
import ar from "../../../../../lang/ar/ar.json";
import en from "../../../../../lang/en/en.json";
import fr from "../../../../../lang/fr/fr.json";
// import icon from "../../../../../public/assets/icons/Feed Convertion Ratio FCR Program.webp"; // Updated icon
import useEnterKeyPress from "../../../../../utils/EnterCalculateBtn";
import ProgramExplaination from "../../../../../shared/ProgramExplaination";

// Define the ST_FCR array in JavaScript
const ST_FCR = [
	// Array values
	0, 0.33, 0.64, 0.91, 1.14, 1.31, 1.45, 1.57, 1.67, 1.73, 1.77, 1.82, 1.83,
	1.83, 1.83, 1.85, 1.88, 1.89, 1.89, 1.9, 1.9, 1.91, 1.91, 1.92, 1.94, 1.96,
	1.98, 2.02, 2.05, 2.1, 2.15, 2.19, 2.24, 2.28, 2.33, 2.37, 2.42, 2.46, 2.5,
	2.55, 2.59, 2.64, 2.68, 2.7, 2.72, 2.73, 2.75, 2.76, 2.78, 2.79, 2.8, 2.81,
	2.82, 2.84, 2.85, 2.86, 2.87, 2.88, 2.89, 2.9, 2.92, 2.93, 2.94, 2.95, 2.97,
	2.99, 3.01, 3.03, 3.05, 3.07, 3.09, 3.11, 3.13, 3.14, 3.16, 3.18, 3.2, 3.22,
	3.24, 3.26, 3.27, 3.29, 3.31, 3.33, 3.34, 3.36, 3.38, 3.39, 3.41, 3.44, 3.46,
	3.49, 3.51, 3.54, 3.56, 3.59, 3.61, 3.64, 3.66, 3.68, 3.71, 3.73, 3.75, 3.77,
	3.8, 3.83, 3.86, 3.89, 3.92, 3.95, 3.98, 4.02, 4.05, 4.08, 4.11, 4.14, 4.17,
	4.2, 4.24, 4.27, 4.31, 4.35, 4.39, 4.43, 4.47, 4.52, 4.56,
];

const FeedConvertionRatioFCRForWhitePulletsProgram = () => {
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [ageOfBirds, setAgeOfBirds] = useState("");
	const [totalFeedIntake, setTotalFeedIntake] = useState("");
	const [averageBodyWeight, setAverageBodyWeight] = useState("");
	const [weeks, setWeeks] = useState("");
	const [result, setResult] = useState("");

	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	let newLang =
		language === "en"
			? en.services.layersManagment
			: language === "ar"
			? ar.services.layersManagment
			: fr.services.layersManagment;

	const calculateResults = () => {
		const numBirds = parseFloat(numberOfBirds);
		const age = parseFloat(ageOfBirds);
		const totalFeed = parseFloat(totalFeedIntake);
		const avgBodyWeight = parseFloat(averageBodyWeight);

		if (
			!isNaN(numBirds) &&
			!isNaN(age) &&
			!isNaN(totalFeed) &&
			!isNaN(avgBodyWeight) &&
			numBirds >= 0 &&
			age >= 0
		) {
			// Calculate the week based on the bird's age
			const weeksCalculated = Math.ceil(age / 7);
			setWeeks(weeksCalculated);

			// Get the standard FCR value for the given age
			const normalFCR = ST_FCR[age] || "N/A"; // Handle case if age is out of range

			// Calculate FCR
			const res0 = totalFeed / numBirds;
			const res1 = (res0 / avgBodyWeight) * 1000;

			// Update the result
			setResult(
				`${newLang.NormalFCR} = ${normalFCR}\n\n${
					newLang.YourFCR
				} = ${res1.toFixed(2)} \n\n${weeksCalculated} ${newLang.weeks}`
			);
		} else {
			setResult(lang.InvalidInputMessage);
		}
	};

	useEnterKeyPress(calculateResults);

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{
					newLang.whitePulletsManagmentPrograms
						.FeedConvertionRatioFCRForWhitePulletsProgram
				}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.NumberOfBirds}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={lang.EnterNumberOfBirds}
					/>
					<InputField
						label={`${lang.AgeOfBirds}:`}
						value={ageOfBirds}
						onChange={(e) => setAgeOfBirds(e.target.value)}
						placeholder={lang.EnterAgeOfBirds}
					/>
					<InputField
						label={`${lang.TotalFeedIntake}:`}
						value={totalFeedIntake}
						onChange={(e) => setTotalFeedIntake(e.target.value)}
						placeholder={lang.EnterTotalFeedIntake}
					/>
					<InputField
						label={`${lang.AverageBodyWeight}:`}
						value={averageBodyWeight}
						onChange={(e) => setAverageBodyWeight(e.target.value)}
						placeholder={lang.EnterAverageBodyWeight}
					/>
					{/* <InputField
						label={`${lang.Weeks}:`}
						value={weeks}
						placeholder={lang.WeeksCalculatedPlaceholder}
						readOnly
					/> */}
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={calculateResults}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={"/assets/icons/Feed Convertion Ratio FCR Program.webp"}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
			{/* <ProgramExplaination
				programTitle={lang.feed_Analysis}
				explaination={lang.feed_Analysis}
			/> */}
		</section>
	);
};

export default FeedConvertionRatioFCRForWhitePulletsProgram;
