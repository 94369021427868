import React, { useState } from "react";
import InputField from "../../../../../components/Calculations/InputField";
import { useSelector } from "react-redux";
import ar from "../../../../../lang/ar/ar.json";
import en from "../../../../../lang/en/en.json";
import fr from "../../../../../lang/fr/fr.json";
// import icon from "../../../../../public/assets/icons/Normal Body Weight For Brown Pullets Program.webp"; // Updated icon
import useEnterKeyPress from "../../../../../utils/EnterCalculateBtn";
import ProgramExplaination from "../../../../../shared/ProgramExplaination";

function NormalBodyWeightForBrownPulletsProgram() {
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [ageInDays, setAgeInDays] = useState("");
	const [result, setResult] = useState("");
	const [weeks, setWeeks] = useState("");

	// Standard body weight array
	const ST_B_W = [
		43, 47, 51, 55, 59, 63, 67, 71, 76, 81, 88, 96, 104, 114, 124, 134, 145,
		156, 166, 177, 188, 200, 210, 220, 230, 240, 250, 260, 270, 282, 294, 306,
		318, 330, 342, 354, 368, 382, 396, 410, 424, 438, 453, 468, 483, 498, 513,
		528, 543, 558, 573, 588, 603, 618, 633, 648, 664, 680, 696, 713, 730, 747,
		764, 781, 797, 813, 829, 845, 860, 875, 890, 905, 920, 935, 950, 965, 980,
		995, 1010, 1025, 1040, 1055, 1070, 1085, 1100, 1113, 1126, 1137, 1148, 1159,
		1170, 1180, 1190, 1200, 1210, 1220, 1230, 1240, 1250, 1260, 1270, 1280,
		1290, 1300, 1310, 1320, 1330, 1340, 1350, 1360, 1370, 1380, 1390, 1400,
		1410, 1420, 1430, 1440, 1450, 1460, 1470, 1480, 1490, 1500, 1510, 1520,
		1530,
	];

	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	let newLang =
		language === "en"
			? en.services.layersManagment
			: language === "ar"
			? ar.services.layersManagment
			: fr.services.layersManagment;

	const calculateResults = () => {
		const numBirds = parseFloat(numberOfBirds);
		const age = parseFloat(ageInDays);

		if (!isNaN(numBirds) && !isNaN(age) && numBirds >= 0 && age >= 0) {
			if (age >= ST_B_W.length) {
				alert(newLang.BirdAgeExceedsLimit); // Assuming `BirdAgeExceedsLimit` is a localized message
				return;
			}

			const weeksCalculated = Math.ceil(age / 7);
			setWeeks(weeksCalculated);

			const bodyWeight = ST_B_W[age];
			setResult(
				`${newLang.normalBodyWeight} = ${bodyWeight} ${newLang.gram}\n${weeksCalculated} ${newLang.weeks}`
			);
		} else {
			setResult(lang.InvalidInputMessage);
		}
	};

	useEnterKeyPress(calculateResults);

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{
					newLang.brownPulletsManagmentPrograms
						.NormalBodyWeightForBrownPulletsProgram
				}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.NumberOfBirds}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={lang.EnterNumberOfBirds}
					/>
					<InputField
						label={`${lang.AgeOfBirds}:`}
						value={ageInDays}
						onChange={(e) => setAgeInDays(e.target.value)}
						placeholder={lang.EnterAgeInDays}
					/>
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={calculateResults}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={
							"/assets/icons/Normal Body Weight For Brown Pullets Program.webp"
						}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
			{/* <ProgramExplaination
				programTitle={lang.feed_Analysis}
				explaination={lang.feed_Analysis}
			/> */}
		</section>
	);
}

export default NormalBodyWeightForBrownPulletsProgram;
