import React, { useState } from "react";
import { useSelector } from "react-redux";
import ar from "../../../../../lang/ar/ar.json";
import en from "../../../../../lang/en/en.json";
import fr from "../../../../../lang/fr/fr.json";
import useEnterKeyPress from "../../../../../utils/EnterCalculateBtn";
import InputField from "../../../../../components/Calculations/InputField";
import ProgramExplaination from "../../../../../shared/ProgramExplaination";
// import icon from "../../../../../public/assets/icons/Feed Consumption Rate For Brown pullets Programs.webp"; // not yet

const CumulativeEggProductionForWhiteLayingHensProgram = () => {
	const [ageOfBirds, setAgeOfBirds] = useState("");
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [result, setResult] = useState("");
	const cumEgg = [
		10, 20, 40, 60, 90, 120, 150, 190, 240, 300, 370, 460, 560, 680, 820, 990,
		1190, 1420, 1680, 1960, 2260, 2590, 2950, 3340, 3760, 4210, 4690, 5220,
		5790, 6410, 7070, 7760, 8480, 9230, 10010, 10810, 11630, 12460, 13300,
		14150, 15010, 15880, 16760, 17650, 18550, 19460, 20370, 21290, 22210, 23140,
		24070, 25010, 25950, 26890, 27840, 28790, 29740, 30690, 31640, 32590, 33540,
		34490, 35440, 36390, 37340, 38290, 39240, 40190, 41140, 42090, 43040, 43990,
		44940, 45890, 46840, 47790, 48740, 49690, 50640, 51590, 52540, 53490, 54440,
		55390, 56340, 57290, 58240, 59190, 60140, 61090, 62040, 62990, 63940, 64890,
		65840, 66790, 67740, 68690, 69640, 70590, 71540, 72490, 73440, 74390, 75340,
		76290, 77240, 78190, 79140, 80090, 81040, 81990, 82940, 83890, 84840, 85790,
		86740, 87690, 88640, 89580, 90520, 91460, 92400, 93340, 94280, 95220, 96160,
		97100, 98040, 98980, 99920, 100860, 101800, 102740, 103670, 104600, 105530,
		106460, 107390, 108320, 109250, 110180, 111110, 112040, 112970, 113900,
		114830, 115760, 116690, 117610, 118530, 119450, 120370, 121290, 122210,
		123130, 124050, 124970, 125890, 126810, 127730, 128650, 129570, 130490,
		131410, 132330, 133250, 134170, 135090, 136010, 136930, 137840, 138750,
		139660, 140570, 141480, 142390, 143300, 144210, 145120, 146030, 146940,
		147850, 148760, 149670, 150580, 151490, 152400, 153310, 154220, 155130,
		156040, 156940, 157840, 158740, 159640, 160540, 161440, 162340, 163240,
		164140, 165040, 165940, 166840, 167740, 168640, 169540, 170440, 171340,
		172240, 173140, 174040, 174940, 175840, 176740, 177640, 178540, 179440,
		180340, 181240, 182130, 183020, 183910, 184800, 185690, 186580, 187470,
		188360, 189250, 190140, 191030, 191920, 192810, 193700, 194590, 195480,
		196370, 197260, 198150, 199040, 199930, 200810, 201690, 202570, 203450,
		204330, 205210, 206090, 206970, 207850, 208730, 209610, 210490, 211370,
		212250, 213120, 213990, 214860, 215730, 216600, 217470, 218340, 219210,
		220080, 220950, 221820, 222690, 223560, 224430, 225300, 226170, 227040,
		227910, 228780, 229650, 230520, 231380, 232240, 233100, 233960, 234820,
		235680, 236540, 237400, 238260, 239120, 239980, 240840, 241700, 242560,
		243420, 244280, 245140, 246000, 246860, 247720, 248580, 249430, 250280,
		251130, 251980, 252830, 253680, 254530, 255380, 256230, 257080, 257930,
		258780, 259630, 260480, 261330, 262180, 263030, 263880, 264730, 265580,
		266430, 267270, 268110, 268950, 269790, 270630, 271470, 272310, 273150,
		273990, 274830, 275670, 276510, 277350, 278180, 279010, 279840, 280670,
		281500, 282330, 283160, 283990, 284820, 285650, 286480, 287310, 288140,
		288970, 289800, 290630, 291460, 292290, 293120, 293950, 294780, 295610,
		296430, 297250, 298070, 298890, 299710, 300530, 301350, 302170, 302990,
		303810, 304630, 305450, 306270, 307090, 307910, 308730, 309550, 310370,
		311190, 312010, 312830, 313640, 314450, 315260, 316070, 316880, 317690,
		318500, 319310, 320120, 320930, 321740, 322550, 323360, 324170, 324980,
		325790, 326600, 327410, 328220, 329030, 329840, 330650, 331460, 332270,
		333080, 333890, 334700, 335510, 336310, 337110, 337910, 338710, 339510,
		340310, 341110, 341910, 342710, 343510, 344310, 345110, 345910, 346710,
		347500, 348290, 349080, 349870, 350660, 351450, 352240, 353030, 353820,
		354610, 355400, 356190, 356980, 357770, 358550, 359330, 360110, 360890,
		361670, 362450, 363230, 364010, 364790, 365570, 366350, 367130, 367910,
		368690, 369460, 370230, 371000, 371770, 372540, 373310, 374080, 374840,
		375600, 376360, 377120, 377880, 378640, 379400, 380160, 380920, 381680,
		382440, 383200, 383960, 384720, 385470, 386220, 386970, 387720, 388470,
		389220, 389970, 390710, 391450, 392190, 392930, 393670, 394410, 395150,
		395890, 396630, 397370, 398110, 398850, 399590, 400330, 401060, 401790,
		402520, 403250, 403980, 404710, 405440, 406160, 406880, 407600, 408320,
		409040, 409760, 410480, 411200, 411920, 412640, 413360, 414080, 414800,
		415520, 416230, 416940, 417650, 418360, 419070, 419780, 420490, 421200,
		421910, 422620, 423330, 424040, 424750, 425460, 426160, 426860, 427560,
		428260, 428960, 429660, 430360, 431060, 431760, 432460, 433160, 433860,
		434560, 435260, 435950, 436640, 437330, 438020,
	];
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	let newLang =
		language === "en"
			? en.services.layersManagment
			: language === "ar"
			? ar.services.layersManagment
			: fr.services.layersManagment;

	const calculateEggProduction = () => {
		if (ageOfBirds < 120 || ageOfBirds > 658) {
			alert(
				"Minimum Age 120 days (18 weeks) and Maximum age 658 days (94 weeks)"
			);
			return;
		}

		const index = ageOfBirds - 120;
		if (index < 0 || index >= cumEgg.length) {
			alert("Invalid index for cumulative egg production array");
			return;
		}

		const eggProduction = cumEgg[index];
		const totalEggs = (eggProduction * numberOfBirds) / 1000;
		const trayEggs = totalEggs / 30;

		setResult(
			`${newLang.CumulativeEggProduction} = ${totalEggs} ${newLang.Egg}.\n\n${newLang.CumulativeEggProduction} = ${trayEggs} ${newLang.tray} (30 ${newLang.Egg}).`
		);
	};

	useEnterKeyPress(calculateEggProduction);

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{
					newLang.whiteLayingHensManagmentPrograms
						.CumulativeEggProductionForWhiteLayingHensProgram
				}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.NumberOfBirds}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={lang.EnterNumberOfBirds}
					/>
					<InputField
						label={`${lang.AgeOfBirds}:`}
						value={ageOfBirds}
						onChange={(e) => setAgeOfBirds(e.target.value)}
						placeholder={lang.EnterAgeOfBirds}
					/>
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={calculateEggProduction}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={
							"/assets/icons/Feed Consumption Rate For Brown pullets Programs.webp"
						}
						alt='Egg Production Calculator'
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
			{/* <ProgramExplaination
				programTitle={lang.feed_Analysis}
				explaination={lang.feed_Analysis}
			/> */}
		</section>
	);
};

export default CumulativeEggProductionForWhiteLayingHensProgram;
