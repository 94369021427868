import React, { useState } from "react";
import InputField from "../../../../../components/Calculations/InputField";
import { useSelector } from "react-redux";
import ar from "../../../../../lang/ar/ar.json";
import en from "../../../../../lang/en/en.json";
import fr from "../../../../../lang/fr/fr.json";
import useEnterKeyPress from "../../../../../utils/EnterCalculateBtn";
import ProgramExplaination from "../../../../../shared/ProgramExplaination";
// import icon from "../../../../../public/assets/icons/Feed Consumption Rate For Brown pullets Programs.webp"; // Updated icon
const WaterConsumptionRateForBrownLayingHensProgram = () => {
	const [age, setAge] = useState("");
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [weeks, setWeeks] = useState("");
	const [result, setResult] = useState("");

	const waterArray = [
		136, 137, 137, 138, 138, 139, 140, 142, 144, 146, 148, 150, 152, 154, 156,
		158, 160, 162, 164, 166, 168, 170, 172, 174, 176, 178, 180, 182, 183, 184,
		185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 195, 195, 195, 195,
		195, 196, 196, 196, 196, 196, 196, 196, 196, 196, 197, 197, 197, 197, 197,
		197, 197, 197, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198,
		198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198,
		198, 199, 199, 199, 199, 199, 199, 199, 199, 199, 199, 199, 199, 199, 199,
		199, 199, 199, 199, 199, 199, 199, 199, 199, 199, 199, 199, 199, 199, 199,
		199, 199, 199, 199, 199, 199, 200, 200, 200, 200, 200, 200, 200, 200, 200,
		200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200,
		200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200,
		200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200,
		200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200,
		200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200,
		200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200,
		200, 200, 200, 200, 200, 200, 198, 198, 198, 198, 198, 198, 198, 198, 198,
		198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198,
		198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198,
		198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198,
		198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198,
		198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198,
		198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198,
		198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198,
		198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198,
		198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198,
		198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198,
		198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198,
		198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198,
		198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198,
		198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198,
		198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198,
		198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198,
		198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198, 198,
		198, 198, 198, 198, 198, 198, 198, 198, 198, 196, 196, 196, 196, 196, 196,
		196, 196, 196, 196, 196, 196, 196, 196, 196, 196, 196, 196, 196, 196, 196,
		196, 196, 196, 196, 196, 196, 196, 196, 196, 196, 196, 196, 196, 196,
	];

	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	let newLang =
		language === "en"
			? en.services.layersManagment
			: language === "ar"
			? ar.services.layersManagment
			: fr.services.layersManagment;

	const handleCalculate = () => {
		const ageValue = parseInt(age, 10);
		const numberOfBirdsValue = parseInt(numberOfBirds, 10);

		if (ageValue < 120 || ageValue > 658) {
			alert(
				"Minimum Age 120 days (18 weeks) and Maximum age 658 days (94 weeks)"
			);
			return;
		}

		const calculatedWeeks = Math.ceil(ageValue / 7);
		setWeeks(calculatedWeeks);

		const index = ageValue - 120;
		const waterConsumption = (waterArray[index] * numberOfBirdsValue) / 1000;
		setResult(
			`${newLang.WaterConsumptionRate} = ${waterConsumption} ${lang.Liter}\n\n${calculatedWeeks} ${newLang.weeks}`
		);
	};

	useEnterKeyPress(handleCalculate);

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{
					newLang.brownLayingHensManagmentPrograms
						.WaterConsumptionRateForBrownLayingHensProgram
				}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.NumberOfBirds}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={lang.EnterNumberOfBirds}
					/>
					<InputField
						label={`${lang.AgeOfBirds}:`}
						value={age}
						onChange={(e) => setAge(e.target.value)}
						placeholder={lang.EnterAgeOfBirds}
					/>
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={handleCalculate}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={
							"/assets/icons/Feed Consumption Rate For Brown pullets Programs.webp"
						}
						alt='icon'
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
			{/* <ProgramExplaination
				programTitle={lang.feed_Analysis}
				explaination={lang.feed_Analysis}
			/> */}
		</section>
	);
};

export default WaterConsumptionRateForBrownLayingHensProgram;
