import React from "react";
import "./ContactForm.scss";
// import icon from "../../../public/assets/banner/icons/Calling.png";
import { useSelector } from "react-redux";
import ar from "../../lang/ar/ar.json";
import en from "../../lang/en/en.json";
import fr from "../../lang/fr/fr.json";

const ContactForm = () => {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.contact
			: language === "ar"
			? ar.contact
			: fr.contact;
	return (
		<form>
			<div className='row'>
				<div className='col-lg-6'>
					<div class='form-group'>
						<label>{lang.name}</label>
						<input
							type='email'
							class='form-control'
							placeholder={lang.Enter_your_name + "..."}
						/>
					</div>
				</div>
				<div className='col-lg-6'>
					<div class='form-group'>
						<label>{lang.e_mail_Address}</label>
						<input
							type='email'
							class='form-control'
							placeholder={lang.Enter_email_address + "..."}
						/>
					</div>
				</div>
				<div className='col-lg-6'>
					<div class='form-group'>
						<label>{lang.Service}</label>
						<select class='form-control'>
							<option>Teeth Whitening</option>
							<option>Teeth Whitening</option>
							<option>Teeth Whitening</option>
							<option>Teeth Whitening</option>
						</select>
					</div>
				</div>
				<div className='col-lg-6'>
					<div class='form-group'>
						<label>Department</label>
						<select class='form-control'>
							<option>Select Department</option>
							<option>Select Department</option>
							<option>Select Department</option>
							<option>Select Department</option>
						</select>
					</div>
				</div>
				<div className='col-lg-12'>
					<div class='form-group'>
						<label for='exampleFormControlTextarea1'>{lang.Messages}</label>
						<textarea
							class='form-control'
							placeholder={lang.Enter_your_messages + "..."}
							rows='3'
						></textarea>
					</div>
				</div>

				<div className='col-lg-6'>
					<button
						type='submit'
						class='btn appointment-btn'
					>
						{lang.book_now}
					</button>
				</div>
				<div className='col-lg-6'>
					<div className='appointment-call'>
						<div className='icon'>
							<img
								src={"/assets/banner/icons/Calling.png"}
								alt='icon'
							/>
						</div>
						<div className='call-text'>
							<p>{lang.dr_name}</p>
							<h6>
								<a
									href='https://wa.me/201000135613'
									target='_blank'
									rel='noopener noreferrer'
									style={{
										color: "#181945",
										textDecorationLine: "none",
										textWrap: "nowrap",
									}}
								>
									002 01000135613
								</a>
							</h6>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

export default ContactForm;
