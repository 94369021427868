import React, { useState } from "react";
import InputField from "../../../../../components/Calculations/InputField";
import { useSelector } from "react-redux";
import ar from "../../../../../lang/ar/ar.json";
import en from "../../../../../lang/en/en.json";
import fr from "../../../../../lang/fr/fr.json";
// import icon from "../../../../../public/assets/icons/Total Feed Consumption For White Laying Hens Program.webp"; // Update icon path
import useEnterKeyPress from "../../../../../utils/EnterCalculateBtn";
import ProgramExplaination from "../../../../../shared/ProgramExplaination";

// Define the CFI array in JavaScript
const CFI = [
	5346, 5414, 5483, 5553, 5624, 5696, 5769, 5842, 5915, 5988, 6062, 6136, 6210,
	6285, 6360, 6435, 6511, 6587, 6663, 6740, 6817, 6894, 6971, 7048, 7125, 7203,
	7281, 7359, 7437, 7516, 7595, 7674, 7754, 7834, 7914, 7994, 8074, 8155, 8236,
	8317, 8399, 8481, 8564, 8647, 8730, 8814, 8898, 8983, 9068, 9154, 9240, 9327,
	9414, 9502, 9590, 9679, 9768, 9858, 9948, 10039, 10130, 10221, 10312, 10404,
	10496, 10588, 10681, 10774, 10867, 10960, 11054, 11148, 11242, 11336, 11430,
	11524, 11618, 11712, 11806, 11900, 11994, 12088, 12182, 12276, 12370, 12464,
	12558, 12652, 12746, 12840, 12934, 13028, 13122, 13216, 13310, 13404, 13498,
	13592, 13686, 13780, 13874, 13969, 14064, 14159, 14254, 14349, 14444, 14539,
	14634, 14729, 14824, 14919, 15014, 15109, 15204, 15299, 15394, 15489, 15584,
	15679, 15774, 15869, 15964, 16059, 16154, 16249, 16344, 16439, 16534, 16629,
	16724, 16819, 16914, 17009, 17104, 17199, 17294, 17389, 17484, 17579, 17674,
	17769, 17864, 17959, 18054, 18149, 18244, 18339, 18434, 18529, 18624, 18719,
	18814, 18909, 19004, 19100, 19196, 19292, 19388, 19484, 19580, 19676, 19772,
	19868, 19964, 20060, 20156, 20252, 20348, 20444, 20540, 20636, 20732, 20828,
	20924, 21020, 21116, 21212, 21308, 21404, 21500, 21596, 21692, 21788, 21884,
	21981, 22078, 22175, 22272, 22369, 22466, 22563, 22660, 22757, 22854, 22951,
	23048, 23145, 23242, 23339, 23436, 23533, 23630, 23727, 23824, 23921, 24019,
	24117, 24215, 24313, 24411, 24509, 24607, 24705, 24803, 24901, 24999, 25098,
	25197, 25296, 25395, 25494, 25593, 25692, 25791, 25890, 25989, 26088, 26187,
	26286, 26385, 26484, 26583, 26682, 26782, 26882, 26982, 27082, 27182, 27282,
	27382, 27482, 27582, 27682, 27782, 27882, 27982, 28082, 28182, 28282, 28382,
	28482, 28582, 28682, 28782, 28882, 28982, 29082, 29182, 29282, 29382, 29482,
	29582, 29682, 29782, 29882, 29982, 30082, 30182, 30282, 30382, 30482, 30582,
	30682, 30782, 30882, 30982, 31082, 31182, 31282, 31382, 31482, 31582, 31682,
	31782, 31882, 31982, 32082, 32182, 32282, 32382, 32482, 32582, 32682, 32782,
	32882, 32982, 33082, 33182, 33282, 33382, 33482, 33582, 33682, 33781, 33880,
	33979, 34078, 34177, 34276, 34375, 34474, 34573, 34672, 34771, 34870, 34969,
	35068, 35167, 35266, 35365, 35464, 35563, 35662, 35761, 35859, 35957, 36055,
	36153, 36251, 36349, 36447, 36545, 36643, 36741, 36839, 36937, 37035, 37133,
	37231, 37329, 37427, 37525, 37623, 37721, 37819, 37917, 38015, 38113, 38211,
	38309, 38407, 38505, 38602, 38699, 38796, 38893, 38990, 39087, 39184, 39281,
	39378, 39475, 39572, 39669, 39766, 39863, 39960, 40057, 40154, 40251, 40348,
	40445, 40542, 40638, 40734, 40830, 40926, 41022, 41118, 41214, 41310, 41406,
	41502, 41598, 41694, 41790, 41886, 41982, 42078, 42174, 42270, 42366, 42462,
	42558, 42653, 42748, 42843, 42938, 43033, 43128, 43223, 43318, 43413, 43508,
	43603, 43698, 43793, 43888, 43982, 44076, 44170, 44264, 44358, 44452, 44546,
	44640, 44734, 44828, 44922, 45016, 45110, 45204, 45297, 45390, 45483, 45576,
	45669, 45762, 45855, 45948, 46041, 46134, 46227, 46320, 46413, 46506, 46599,
	46692, 46785, 46878, 46971, 47064, 47157, 47250, 47343, 47436, 47529, 47622,
	47715, 47808, 47901, 47994, 48087, 48180, 48273, 48366, 48459, 48551, 48643,
	48735, 48827, 48919, 49011, 49103, 49195, 49287, 49379, 49471, 49563, 49655,
	49747, 49839, 49931, 50023, 50115, 50207, 50299, 50391, 50483, 50575, 50667,
	50759, 50851, 50943, 51035, 51127, 51219, 51311, 51403, 51495, 51587, 51679,
	51770, 51861, 51952, 52043, 52134, 52225, 52316, 52407, 52498, 52589, 52680,
	52771, 52862, 52953, 53044, 53135, 53226, 53317, 53408, 53499, 53590, 53681,
	53772, 53863, 53954, 54045, 54136, 54227, 54318, 54409, 54500, 54591, 54682,
	54773, 54864, 54955, 55046, 55137, 55228, 55319, 55410, 55501, 55592, 55683,
	55774, 55865,
];

const TotalFeedConsumptionForWhiteLayingHensProgram = () => {
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [ageOfBirds, setAgeOfBirds] = useState("");
	const [weeks, setWeeks] = useState("");
	const [result, setResult] = useState("");

	const language = useSelector((state) => state?.lang?.language);
	const lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	let newLang =
		language === "en"
			? en.services.layersManagment
			: language === "ar"
			? ar.services.layersManagment
			: fr.services.layersManagment;

	const calculateResults = () => {
		const numberOfBirdsValue = parseInt(numberOfBirds, 10);
		const age = parseInt(ageOfBirds, 10);

		if (
			!isNaN(numberOfBirdsValue) &&
			numberOfBirdsValue > 0 &&
			!isNaN(age) &&
			age >= 0
		) {
			const weeksCalculated = Math.ceil(age / 7);
			setWeeks(weeksCalculated);

			// Get the feed intake rate from CFI based on age
			const index = age - 120; // Adjust index based on your data
			const feedIntake = CFI[index] || 0; // Handle case if age is out of range

			// Calculate total feed consumption in grams
			const totalFeed = feedIntake * numberOfBirdsValue;

			// Convert to kilograms and tons
			const totalFeedInKg = totalFeed / 1000; // Convert grams to kilograms
			const totalFeedInTons = totalFeedInKg / 1000; // Convert kilograms to tons

			setResult(
				`Normal cumulative feed intake = ${totalFeedInKg.toFixed(
					2
				)} Kg = ${totalFeedInTons.toFixed(2)} ton.\n\n${weeksCalculated} ${
					newLang.weeks
				}`
			);
		} else {
			setResult(lang.InvalidInputMessage);
		}
	};

	useEnterKeyPress(calculateResults);

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{
					newLang.whiteLayingHensManagmentPrograms
						.TotalFeedConsumptionForWhiteLayingHensProgram
				}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.NumberOfBirds}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={lang.EnterNumberOfBirds}
					/>
					<InputField
						label={`${lang.AgeOfBirds}:`}
						value={ageOfBirds}
						onChange={(e) => setAgeOfBirds(e.target.value)}
						placeholder={lang.EnterAgeOfBirds}
					/>
					<InputField
						label={`${lang.WeeksCalculated}:`}
						value={weeks}
						placeholder={lang.WeeksCalculatedPlaceholder}
						readOnly
					/>
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={calculateResults}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={
							"/assets/icons/Total Feed Consumption For White Laying Hens Program.webp"
						}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
			{/* <ProgramExplaination
				programTitle={lang.feed_Analysis}
				explaination={lang.feed_Analysis}
			/> */}
		</section>
	);
};

export default TotalFeedConsumptionForWhiteLayingHensProgram;
